import React, {useEffect, useState, ChangeEvent, FormEvent} from 'react';
import axios from '../axios';

const Contact = ()=>{
    const [formData, setFormData] = useState({
        nome:'',
        email:'',
        mensagem:''
    });
    const [value, setValue] = useState('');
    const handleChange = (event:any) => {
        setValue(event.target.value);
        setFormData({...formData, mensagem:value})
    };

    function handleIputChange(event: ChangeEvent<HTMLInputElement>){
        const {name, value} = event.target;
        setFormData({
            ...formData, [name]:value
        });
    }

    async function sendData(){        
        console.log(formData);
        await axios.post('contato', formData, {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(()=>{
            alert('Mensagem enviada');
            clear();
        })
        .catch((error)=>{
            alert(error);
        })
    }

    function clear(){
        setFormData({
            nome:'',
            email:'',
            mensagem:''
        });
        setValue('');
    }

    return (
        <div className="home-content">
            <div className="contact">
                <fieldset className="campo">
                    <legend>Nome</legend>
                    <input type="text" autoComplete="off" className="field" name="nome" id="nome" value={formData.nome} onChange={handleIputChange}/>
                </fieldset>
                <fieldset className="campo">
                    <legend>Email</legend>
                    <input type="email" className="field" autoComplete="off"  name="email" id="email" value={formData.email} onChange={handleIputChange}/>
                </fieldset>
                <fieldset className="campo">
                    <legend>Mensagem</legend>
                    <textarea  className="field" name="mensagem" id="mensagem"  autoComplete="off" value={value} onBlur={handleChange} onChange={handleChange} />
                </fieldset>
                <div className="buttons">
                    <button className="btn-form-submit" type="button" onClick={sendData}>Enviar</button>
                    <button className="btn-form-clear" type="button" onClick={clear}>Limpar</button>
                </div>
            </div>
        </div>
        )
};

export default Contact;