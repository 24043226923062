import React from 'react';
import { Route, Switch,} from 'react-router-dom';
import Apis from './components/Apis';
import Home from './components/home';
import Blog from './components/blog';
import Contact from './components/contact';
import Social from './components/social';

const Router = () =>{
    return (
                <Switch>
                    <Route component={Apis} path="/apis"/>
                    <Route component={Blog} path="/blog" exact/>
                    <Route component={Social} path="/social" exact/>
                    <Route component={Contact} path="/contact" />
                    <Route component={Home} path="/" exact/>
                </Switch>
                );
    }
export default Router;
/*

*/