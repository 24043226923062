import React from 'react';
import {Link} from 'react-router-dom';

const ItemContent = (props:any)=> {
const {title, type, description, link, linkDescription, tags, date, color} = props;

    return(
        <div className={`item-content ${color}`} >
            <div className={`title ${color}`}>
               <h2>{title}</h2>
               >> {type}
            </div>
            <div className={`description ${color}`}>
                {description}
                <div className={`link ${color}`}>
                    <Link to={link} target="_blank" className={`${color}`}>{linkDescription}</Link>
                </div>
            </div>
            <div className={`footer ${color}`}>
                <div className="tags">{tags.map((desc:string)=>`#${desc} `)}</div>
                <div className="date">{date}</div>
            </div>
        </div>
    )

}

export default ItemContent
;