import React from 'react';
import {Link} from 'react-router-dom';

const Social = () => {

    return (
        <div className="social">
            <div className="social-content">
                <div className="social"><a href="//github.com/crqcastro" target="_blank"><img src="/images/github.svg" /><span>/crqcastro</span></a></div>
                <div className="social"><a href="//linkedin.com/in/cesarrqcastro" target="_blank"><img src="/images/linkedin.svg" /><span>/cesarrqcastro</span></a></div>
                <div className="social"><a href="//dev.to/crqcastro" target="_blank"><img src="/images/dev.svg" /><span>/cesarrqcastro</span></a></div>
            </div>
        </div>
    )

}

export default Social;