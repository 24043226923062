import React, {useState, useEffect} from 'react';
import ItemContent from './item-content';
import axios from '../axios';


interface Postagem {
    id:number,
    titulo:string,
    descricao:string,
    tipo:string,
    link:string,
    linkDescricao:string,
    tags:[],
    data:string
}

const Apis = () => {
    
    const [postagens, setPostagens] = useState<Postagem[]>([]);
    const [disabled, setDisabled] = useState(true);
    const [offset, setOffset] = useState(0);
    const limit = 50;
    const [btnText, setBtnText] = useState('Sem mais postagens');

    useEffect(()=>{
        axios.get<Postagem[]>('apis')
        .then(response=>{
            setPostagens(response.data);
        })
    }, []);

    useEffect(()=>setOffset(postagens.length),[postagens]);

    useEffect(()=>{
        if(postagens.length%limit===0){
            if(postagens.length>=limit ){
                setDisabled(false);
                setBtnText('Carregar mais postagens')
            }
        }else{
            setDisabled(true);
            setBtnText('Sem mais postagens');
        }
    },[postagens]);

    const readMore = ()=>{
        axios.get<Postagem[]>(`api?offset=${offset}`)
        .then(response=>{
            const newPosts:Postagem[] = response.data;
            setPostagens([...postagens, ...newPosts]);
        })
    }

 return (
        <div className="home">
            <div className="home-content">
            {postagens.length===0?(<div className="null"><h1>NULL</h1></div>):
                postagens.map((post, idx)=>{
                   return(<ItemContent
                        key={idx}
                        id={post.id}
                        title={post.titulo}
                        type={post.tipo}
                        description={post.descricao}
                        link={post.link}
                        linkDescription={post.linkDescricao}
                        tags={post.tags}
                        date={post.data}
                        color={post.tipo==="API"?"white":"green"}
                    />)
                })}
            </div>
            <button type="button" onClick={readMore} className={`btn-readmore`} disabled={disabled}>{btnText}</button>
        </div>
    );
}

export default Apis;