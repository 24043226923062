import React from 'react';
import {Link} from 'react-router-dom';

const Menu = () => {
    return (
        <ul className="navbar-nav">
        <li className="logo">
            <Link to="/" className="nav-link"> <span
            className="link-text logo-text">Cesar Castro</span> <svg version="1.0"
                xmlns="http://www.w3.org/2000/svg" width="400px" height="370px"
                viewBox="0 0 4000 3700" preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#ffffff" stroke="none">
                        <path
                    d="M691 3537 c14 -12 54 -46 88 -74 l62 -53 1445 0 1444 0 0 -1284 0 -1285 48 -45 c26 -24 59 -54 75 -66 l27 -21 0 1425 0 1426 -1607 0 -1608 -1 26 -22z" />
                        <path
                    d="M2482 3270 c-205 -29 -362 -125 -430 -262 -64 -132 -67 -166 -67 -838 0 -559 1 -610 18 -665 54 -174 198 -306 398 -362 90 -25 337 -25 429 0 173 46 324 162 369 282 35 93 51 231 51 443 l0 192 -269 0 -269 0 -4 -263 c-3 -228 -5 -266 -20 -289 -34 -52 -116 -50 -145 5 -17 30 -18 81 -18 682 0 713 -1 704 58 724 42 15 90 -2 107 -37 9 -19 15 -96 18 -259 l4 -233 269 0 269 0 0 158 c0 174 -18 319 -50 405 -50 133 -204 256 -377 301 -76 20 -255 28 -341 16z" />
                        <path
                    d="M87 2834 c-4 -4 -7 -616 -7 -1361 l0 -1353 1535 0 c844 0 1535 3 1534 8 0 4 -35 36 -76 72 l-76 65 -1384 3 -1383 2 0 1227 0 1226 -62 56 c-68 62 -71 64 -81 55z" />
                        <path
                    d="M1038 2480 c-164 -34 -302 -132 -360 -255 -60 -130 -63 -159 -63 -825 0 -596 0 -606 22 -675 58 -182 200 -306 409 -356 76 -18 109 -20 229 -16 157 5 225 20 331 72 130 63 216 170 249 307 17 70 38 386 33 488 l-3 65 -272 3 -273 2 0 -237 c-1 -325 -8 -353 -91 -353 -58 0 -79 24 -89 105 -12 93 -12 1162 0 1245 10 74 32 100 85 100 80 0 95 -53 95 -337 l0 -193 273 2 272 3 -3 160 c-7 327 -38 433 -162 548 -83 77 -186 131 -292 152 -104 20 -280 18 -390 -5z" />
                    </g>
                </svg>
            </Link>
        </li>
    
        <li className="nav-item">
            <Link to="/apis" className="nav-link"> <svg height="512"
                viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
                    <g id="API">
                        <path d="m31 20h4v2h-4z" />
                        <path d="m21.9 20-.34 3h2.88l-.34-3z" />
                        <path
                    d="m58 54a4 4 0 0 0 -3.86 3h-3.14a1 1 0 0 1 -1-1v-7h2.14a4 4 0 1 0 0-2h-2.14v-15h3a9 
                           9 0 0 0 0-18h-2.06a8.977 8.977 0 0 0 -14.18-6.3 14.982 14.982 0 0 0 -26.45 6.34 
                           8.993 8.993 0 0 0 .69 17.96h3v13h-2.14a4 4 0 1 0 0 2h2.14v9a1 1 0 0 1 -1 1h-3.14a4
                           4 0 1 0 0 2h3.14a3.009 3.009 0 0 0 3-3v-9h8a3.009 3.009 0 0 0 3-3v-12h4v22.14a4 4 
                           0 1 0 2 0v-22.14h4v14a3.009 3.009 0 0 0 3 3h8v7a3.009 3.009 0 0 0 3 3h3.14a3.994 
                           3.994 0 1 0 3.86-5zm-33-10a1 1 0 0 1 -1 1h-8v-13h9zm.01-15.89-.35-3.11h-3.32l-.35 
                           3.11-1.98-.22 1-9a.989.989 0 0 1 .99-.89h4a.989.989 0 0 1 .99.89l1 9zm11.99-5.11a1 
                           1 0 0 1 -1 1h-5v4h-2v-9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1zm2 3h2v-6h-2v-2h6v2h-2v6h2v2h-6zm9 
                           21h-8a1 1 0 0 1 -1-1v-14h9z" />
                    </g>
                </svg> <span className="link-text">API'S</span>
            </Link>
        </li>
    
        <li className="nav-item">
            <Link to="/blog" className="nav-link"> <svg
                viewBox="0 -20 512 511" xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="m67.179688 48.394531h-.101563c-5.523437 0-10 4.476563-10 10 0 5.523438 4.476563 10 10 10h.101563c5.523437 0 10-4.476562 10-10 0-5.523437-4.476563-10-10-10zm0 0" />
                    <path
                    d="m116.710938 48.394531h-.101563c-5.523437 0-10 4.476563-10 10 0 5.523438 4.476563 10 10 10h.101563c5.523437 0 10-4.476562 10-10 0-5.523437-4.476563-10-10-10zm0 0" />
                    <path
                    d="m166.242188 48.394531h-.101563c-5.523437 0-10 4.476563-10 10 0 5.523438 4.476563 10 10 10h.101563c5.523437 0 10-4.476562 10-10 0-5.523437-4.476563-10-10-10zm0 0" />
                    <path
                    d="m444.308594 48.394531h-192.929688c-5.523437 0-10 4.476563-10 10 0 5.519531 4.476563 10 10 10h192.929688c5.523437 0 10-4.480469 10-10 0-5.523437-4.476563-10-10-10zm0 0" />
                    <path
                    d="m262.890625 452.4375c-2.628906 0-5.199219 1.070312-7.070313 2.929688-1.859374 1.859374-2.929687 4.441406-2.929687 7.070312s1.070313 5.210938 2.929687 7.070312c1.871094 1.859376 4.441407 2.929688 7.070313 2.929688s5.210937-1.070312 7.070313-2.929688c1.867187-1.859374 2.929687-4.441406 2.929687-7.070312s-1.0625-5.210938-2.929687-7.070312c-1.859376-1.859376-4.441407-2.929688-7.070313-2.929688zm0 0" />
                    <path
                    d="m480.230469.5h-448.460938c-17.515625 0-31.769531 14.253906-31.769531 31.769531v408.398438c0 17.519531 14.253906 31.769531 31.769531 31.769531h189.230469c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-189.230469c-6.488281 0-11.769531-5.277344-11.769531-11.769531v-324.378907h472v324.378907c0 6.488281-5.28125 11.769531-11.769531 11.769531h-176.5625c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h176.5625c17.515625 0 31.769531-14.25 31.769531-31.769531v-408.398438c0-17.515625-14.253906-31.769531-31.769531-31.769531zm-460.230469 95.785156v-64.015625c0-6.488281 5.28125-11.769531 11.769531-11.769531h448.460938c6.488281 0 11.769531 5.28125 11.769531 11.769531v64.019531h-472zm0 0" />
                    <path
                    d="m178.039062 230.5c0-19.851562-16.148437-36-36-36h-33.667968c-5.523438 0-10 4.476562-10 10v104c0 5.523438 4.476562 10 10 10h33.667968c19.851563 0 36-16.148438 36-36 0-10.214844-4.28125-19.441406-11.140624-26 6.859374-6.558594 11.140624-15.785156 11.140624-26zm-20 52c0 8.820312-7.179687 16-16 16h-23.667968v-32h23.667968c8.820313 0 16 7.175781 16 16zm-39.667968-36v-32h23.667968c8.824219 0 16 7.179688 16 16s-7.175781 16-16 16zm0 0" />
                    <path
                    d="m206.648438 194.5c-5.523438 0-10 4.476562-10 10v80.871094c0 18.269531 14.863281 33.128906 33.128906 33.128906 5.523437 0 10-4.476562 10-10s-4.476563-10-10-10c-7.238282 0-13.128906-5.890625-13.128906-13.128906v-80.871094c0-5.523438-4.476563-10-10-10zm0 0" />
                    <path
                    d="m324.867188 282.289062c0-19.96875-16.242188-36.214843-36.210938-36.214843s-36.210938 16.246093-36.210938 36.214843 16.246094 36.210938 36.210938 36.210938c19.96875 0 36.210938-16.242188 36.210938-36.210938zm-52.421876 0c0-8.941406 7.273438-16.214843 16.210938-16.214843s16.210938 7.273437 16.210938 16.214843c0 8.9375-7.269532 16.210938-16.210938 16.210938s-16.210938-7.273438-16.210938-16.210938zm0 0" />
                    <path
                    d="m377.414062 346.5c-8.9375 0-16.210937-7.273438-16.210937-16.210938 0-5.523437-4.476563-10-10-10s-10 4.476563-10 10c0 19.96875 16.246094 36.210938 36.210937 36.210938 19.96875 0 36.214844-16.242188 36.214844-36.210938v-48c0-19.96875-16.246094-36.214843-36.214844-36.214843-19.964843 0-36.210937 16.246093-36.210937 36.214843s16.246094 36.210938 36.210937 36.210938c5.828126 0 11.332032-1.390625 16.214844-3.847656v15.636718c0 8.9375-7.273437 16.210938-16.214844 16.210938zm0-48c-8.9375 0-16.210937-7.273438-16.210937-16.210938 0-8.941406 7.273437-16.214843 16.210937-16.214843 8.941407 0 16.214844 7.273437 16.214844 16.214843 0 8.9375-7.273437 16.210938-16.214844 16.210938zm0 0" />
                    <path
                    d="m108.371094 416.5h295.257812c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-295.257812c-5.519532 0-10 4.476562-10 10s4.480468 10 10 10zm0 0" />
                </svg> <span className="link-text">BLOG</span>
            </Link>
        </li>
    
        <li className="nav-item">
            <Link to="/contact" className="nav-link"> <svg height="640pt"
                viewBox="-20 -99 640 640" width="640pt"
                xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="m179.25 211c35.691406 0 64.625-28.9375 64.625-64.625 0-35.691406-28.933594-64.625-64.625-64.625s-64.625 28.933594-64.625 64.625c.046875 35.671875 28.953125 64.578125 64.625 64.625zm0-104.125c21.882812 0 39.625 17.738281 39.625 39.625 0 21.882812-17.742188 39.625-39.625 39.625-21.886719 0-39.625-17.742188-39.625-39.625.007812-21.882812 17.742188-39.617188 39.625-39.625zm0 0" />
                    <path
                    d="m179.25 234.875c-26.441406-.195312-51.820312 10.417969-70.25 29.375-18.75 19.125-29 45.125-29 73.375.019531 6.894531 5.605469 12.480469 12.5 12.5h173.5c6.894531-.019531 12.480469-5.605469 12.5-12.5 0-28.25-10.25-54.25-29-73.375-18.429688-18.957031-43.804688-29.570312-70.25-29.375zm-73.375 90.25c2.140625-16.34375 9.507812-31.554688 21-43.375 13.832031-13.996094 32.695312-21.875 52.375-21.875s38.542969 7.878906 52.375 21.875c11.46875 11.832031 18.835938 27.039062 21 43.375zm0 0" />
                    <path
                    d="m537.5-5.25h-475c-34.511719.015625-62.484375 27.988281-62.5 62.5v317.5c.015625 34.511719 27.988281 62.484375 62.5 62.5h475c34.511719-.015625 62.484375-27.988281 62.5-62.5v-317.5c-.015625-34.511719-27.988281-62.484375-62.5-62.5zm37.5 380c-.058594 20.683594-16.816406 37.441406-37.5 37.5h-475c-20.683594-.058594-37.441406-16.816406-37.5-37.5v-317.5c.058594-20.683594 16.816406-37.441406 37.5-37.5h475c20.683594.058594 37.441406 16.816406 37.5 37.5zm0 0" />
                    <path
                    d="m506.75 203.5h-145.625c-6.902344 0-12.5 5.59375-12.5 12.5s5.597656 12.5 12.5 12.5h145.625c6.902344 0 12.5-5.59375 12.5-12.5s-5.597656-12.5-12.5-12.5zm0 0" />
                    <path
                    d="m506.75 283.5h-145.625c-6.902344 0-12.5 5.59375-12.5 12.5s5.597656 12.5 12.5 12.5h145.625c6.902344 0 12.5-5.59375 12.5-12.5s-5.597656-12.5-12.5-12.5zm0 0" />
                    <path
                    d="m506.75 123.5h-145.625c-6.902344 0-12.5 5.59375-12.5 12.5s5.597656 12.5 12.5 12.5h145.625c6.902344 0 12.5-5.59375 12.5-12.5s-5.597656-12.5-12.5-12.5zm0 0" />
                </svg> <span className="link-text">CONTACT</span>
            </Link>
        </li>
    
        <li className="nav-item">
            <Link to="/social" className="nav-link"> <svg version="1.1"
                id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 496 496">
                    <g>
                <g>
                <g>
                        <path
                    d="M34.384,181.04C27.616,202.656,24,225.104,24,248c0,13.928,1.288,27.88,3.832,41.472l15.728-2.936
                            C41.2,273.912,40,260.944,40,248c0-19.28,2.752-38.224,7.92-56.552C64.12,201.872,83.344,208,104,208
                            c57.344,0,104-46.656,104-104S161.344,0,104,0S0,46.656,0,104C0,134.56,13.328,162,34.384,181.04z M104,16
                            c48.52,0,88,39.48,88,88s-39.48,88-88,88s-88-39.48-88-88S55.48,16,104,16z" />
                        <path
                    d="M461.616,314.96C468.384,293.344,472,270.888,472,248c0-13.936-1.288-27.888-3.832-41.472l-15.728,2.944
                            C454.8,222.088,456,235.048,456,248c0,19.272-2.752,38.216-7.92,56.552C431.88,294.128,412.656,288,392,288
                            c-57.344,0-104,46.656-104,104s46.656,104,104,104s104-46.656,104-104C496,361.44,482.672,334,461.616,314.96z M392,480
                            c-48.52,0-88-39.48-88-88c0-48.52,39.48-88,88-88c48.52,0,88,39.48,88,88C480,440.52,440.52,480,392,480z" />
                        <polygon
                    points="96,160 112,160 112,120 144,120 144,104 112,104 112,64 144,64 144,48 96,48 96,104 64,104 64,120 96,120 			" />
                        <path
                    d="M128,336H80c-22.056,0-40,17.944-40,40v32c0,22.056,17.944,40,40,40h48c22.056,0,40-17.944,40-40v-32
                            C168,353.944,150.056,336,128,336z M152,408c0,13.232-10.768,24-24,24H80c-13.232,0-24-10.768-24-24v-32
                            c0-13.232,10.768-24,24-24h48c13.232,0,24,10.768,24,24V408z" />
                        <path
                    d="M352,445.592L448.472,392L352,338.408V445.592z M368,365.592L415.528,392L368,418.408V365.592z" />
                        <path
                    d="M376,160h32c17.648,0,32-14.352,32-32s-14.352-32-32-32h-32c-8.824,0-16-7.176-16-16s7.176-16,16-16h32
                            c8.824,0,16,7.176,16,16v8h16v-8c0-17.648-14.352-32-32-32h-32c-17.648,0-32,14.352-32,32s14.352,32,32,32h32
                            c8.824,0,16,7.176,16,16s-7.176,16-16,16h-32c-8.824,0-16-7.176-16-16v-8h-16v8C344,145.648,358.352,160,376,160z" />
                        <path
                    d="M104,368c-13.232,0-24,10.768-24,24s10.768,24,24,24s24-10.768,24-24S117.232,368,104,368z M104,400
                            c-4.408,0-8-3.584-8-8c0-4.416,3.592-8,8-8s8,3.584,8,8C112,396.416,108.408,400,104,400z" />
                        <path
                    d="M304.616,47.816C294.152,64.04,288,83.296,288,104c0,57.344,46.656,104,104,104s104-46.656,104-104S449.344,0,392,0
                            c-30.536,0-57.944,13.304-76.992,34.328c-34.872-10.896-72.56-13.216-108.48-6.496l2.944,15.728
                            C240.888,37.68,273.824,39.16,304.616,47.816z M392,16c48.52,0,88,39.48,88,88s-39.48,88-88,88c-48.52,0-88-39.48-88-88
                            S343.48,16,392,16z" />
                        <path
                    d="M191.384,448.184C201.848,431.96,208,412.704,208,392c0-57.344-46.656-104-104-104S0,334.656,0,392s46.656,104,104,104
                            c30.56,0,58-13.328,77.04-34.392C202.648,468.384,225.096,472,248,472c13.96,0,27.912-1.288,41.472-3.832l-2.944-15.72
                            C255.176,458.32,222.184,456.832,191.384,448.184z M104,480c-48.52,0-88-39.48-88-88c0-48.52,39.48-88,88-88s88,39.48,88,88
                            C192,440.52,152.52,480,104,480z" />
                        <path
                    d="M304,248c0-30.88-25.12-56-56-56s-56,25.12-56,56c0,30.872,25.12,56,56,56S304,278.872,304,248z M248,288
                            c-22.056,0-40-17.944-40-40c0-22.056,17.944-40,40-40c22.056,0,40,17.944,40,40C288,270.056,270.056,288,248,288z" />
                        <rect x="320" y="240" width="16" height="16" />
                        <rect x="240" y="160" width="16" height="16" />
                        <rect x="240" y="320" width="16" height="16" />
                        <rect x="160" y="240" width="16" height="16" />
                    </g></g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                </svg> <span className="link-text">SOCIAL MEDIAS</span>
            </Link>
        </li>
    
    
    
    </ul>
    )
}


export default Menu;