import React from 'react';
import './App.css';
import Menu from './components/menu';
import Routes from './routes';
import {BrowserRouter } from 'react-router-dom';
function App() {
  return (
      <>
      <BrowserRouter>
         <nav className="navbar">
            <Menu />
         </nav>
         <main>
            <Routes />
         </main>
      </BrowserRouter>  
      </>
      );
}

export default App;
